
<template>
  <div>
    <admin-home v-if="['admin', 'super_admin', 'director'].includes(user.role.name)"></admin-home>
    <student-home  v-if="['student'].includes(user.role.name)"></student-home>
    <coordinator-home v-if="['coordinator'].includes(user.role.name)"></coordinator-home>
    <tech-secretary-home v-if="['tech_secretary'].includes(user.role.name)"></tech-secretary-home>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'HomeScreen',
  components: {
    AdminHome: () => import ('../components/_home_pages/AdminHome.vue'),
    StudentHome: () => import ('../components/_home_pages/StudentHome.vue'),
    CoordinatorHome: () => import ('../components/_home_pages/CoordinatorHome.vue'),
    TechSecretaryHome: () => import ('../components/_home_pages/TechSecretaryHome.vue')
  },
  computed: {
    ...mapState({
      user: state=> state.User.user
    })
  },
}
</script>

<style scoped>

</style>